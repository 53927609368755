@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.content .brand__img {
    text-align: center;
    margin-bottom: 24px;
}

.content .brand_puff_logo_lightbox {
    width: 125px;
    height: 40px;
}

.content {
   padding: 0 24px;
}

/* Styles for modalHeader */
.modalHeader {
    text-align: center;

}

.modalHeader h1 {
    text-align: center;
    font-size: 30px;
    margin-left: $spacing-l;
}

.modalHeader p {
    color: #222222;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
}

@media (max-width: 576px) {
    .modalHeader p {
        margin: 0 22px;
    }
}

@media (max-width: 400px) {
    .modalHeader p {
        margin: 0 10px;
    }
}

/* Styles for modalBody */
.modalBody {
    min-height: 60px;
    //padding-bottom: 20px;
}

.modalBody p {
    font-size: 17px;
    font-weight: normal;
    line-height: 28px;
    margin-bottom: 10px;
}

.modalBody a {
    font-size: 17px;
    line-height: 28px;
}

@media (max-width: 767px) {
    .modalBody .brandLogos img {
        margin: $spacing-xs auto;
        width: 25%;
        height: 15px;
    }

    .modalBody {
        //padding-bottom: 20px;
        
    }
}

.brandLogos {
    display: flex;
    width: 343px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    top: $spacing-s;
    right: $spacing-s;
    margin-bottom: $spacing-s;
}

.modalBody .brandLogos img {
    margin: $spacing-xs auto;
    width: 25%;
    height: 15px;
}



@media (max-width: 400px) {
    .modalBody .brandLogos img {
        margin: $spacing-xs auto;
        width: 25%;
        height: 15px;
    }
}

@media screen and (max-width: 400px) and (max-height: 599px) {
    .modalBody .brandLogos img {
        margin: $spacing-xs auto;
        width: 25%;
        height: 15px;
    }
}
.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: .5;
}
.cshtmlcentrealign {
    text-align: center;
}
