@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.spacing{
    margin-bottom: $spacing-m;
}

.actionButton {
    display: flex;
    flex-direction: column;
}

.pop_up_width{
    width: 343px !important;
}



@media (min-width: 420px) {
    .apiSucessErrorToast > div {
        margin-left: 8px;
        width: 343px !important;
        
    }
  }

  @media (min-width: 420px) {
    .toast-error > div {
        margin-bottom: -5%;
    }
  }


    .toast-success > div {
        margin-bottom: 3%;
    }
