@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';
.static__content {
text-align: left;
margin-top: 20px;
}
// .infotab {
//     margin-top: 2%;
// }

.static__content > p {
    // font-family: 'HM Sans Regular', sans-serif;
    font-size: 17px;
    line-height: 28px;
}

.expanded__text {
    text-align: left;
    line-height: 16px;
    font-size: 13px;
    margin:0;
    // font-family: 'HM Sans Regular', sans-serif;
}

.expanded__text a {
text-decoration: underline;
}

.accordian__holder {
    margin-top: 5%;
}

.img__holder, .brand_puff a img {
    width: 100%;
    
}
.img__holder .info_img {
object-fit: cover;
}

.img__holder > img {
    width: 100%;
}
.brand_puff a img {
    object-fit: cover;
}
.h2_brands {
    font-size: 16px;
    line-height: 20px;
    // font-family: 'HM Sans Semi Bold', sans-serif;
    text-align: left;
    margin: 0;
}
.accordian__label {
    color:#222222;
    line-height: 16px;
    text-align: left;
    // font-family: 'HM Sans Semi Bold', sans-serif;
    font-size: 12px;
    letter-spacing: 1px;    
}

.MuiAccordion-root.Mui-expanded {
    margin : 0 0 12px 0 !important;
}
// .Mui-expanded p.accordian__label {
//     color:#E11F26
// }

// .Mui-expanded svg {
//     fill:#E11F26
// }
.summary_section {
    min-height: 0 !important;
}
.summary_section div:first-child{
    margin: 0;
}
.img__holder {
    margin-top: 31px;
}

.bottomspacing {
    padding-bottom: $spacing-xxxxxl;
}

.accordian__section {
    margin-bottom: 12px;
    box-shadow: none !important;
}

.MuiAccordion-root:before {
    display: none;
}

.explore__brands {
    margin-top: 66px;
}
.explore__brands div.brand_puff {
    position: relative;
    // margin-top: 31px;
}
.explore__brands .img__holder, .item-h {
    position: relative;
}
.item-h {
    margin-bottom: 12px;
}

 .brand_logo {
    position: absolute;
    top: calc(50% - 2.5vh);
    left: 10%;
    right: 10%;
    width: 80%;
    height: 33px;
}
.brand_puff {
    overflow: hidden;
    position: relative;
}

.testspan {
    position: absolute;
    margin: auto;
    top: calc(75% - 2.5vh);
    left: 10%;
    right: 10%;
font-family: 'HMGroupAmpersand-Regular',sans-serif;
    font-size: 1rem;
    line-height: 1.2!important;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0em;
    border-radius: 0em;
    padding: 0.8em 1.8em;
    background: rgba(0,0,0,0.30);
    border-color: #ffffff;
    color: #ffffff!important;
    box-shadow: 0 0em 0em rgb(0 0 0 / 20%);
    width: max-content ;
}
.testspan:before {
    border-width: 1px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border-style: solid;
    border-color: inherit;
    border-image: inherit;
}
.testspan:hover {
    box-shadow: 0 0em 0em rgb(0 0 0 / 20%);
    background: #ffffff;
    border-color: #ffffff;
    color: #2c2c2c!important;
}

.fa-external-link:before {
    content: "\f08e";
}

.fal {
    font-family: 'myfonts';
    font-weight: 300;
}

.testspan i {
    margin-left: 0.5em;
}

.fal {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
@media only screen and (min-width : 768px) {

    .explore__brands > .img__holder {    
    position: absolute;
    display: flex;
    left: 6%;
    right: 0px;
    flex-wrap: wrap;
    }
    .explore__brands .item-h {
        margin: 10px;
        width: 28%;
        position: relative
    }
    .h2_brands {
        text-align: center;
    }
}

@media only screen and (max-width : 767px) {
    .infotab{
        padding : 0 24px;
    }   
    .testspan {
        font-size: .9rem;
    top: calc(80% - 4.2vh);
    }
}



@media only screen and (min-width: 700px) and (max-width: 900px) and (orientation : landscape) {
.testspan {
    font-size: .9rem;
    top: calc(80% - 5vh);
}
}


// For IPAD Pro Devices

/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .testspan {
        top: calc(80% - 1vh);
        }
}

/* Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .testspan {
        top: calc(80% - 2vh);
        }

}

//For IPAD Devices

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .testspan {
        font-size: .9rem;
        top: calc(80% - 2vh);
        } /* your css rules for ipad portrait */
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
   
    .testspan {
        top: calc(80% - 3vh);
        } /* your css rules for ipad landscape */
  }



// @media only screen and (max-width: 699px) and (orientation : landscape) {
//     .testspan {
//         top: calc(80% - 9vh);
//     }
// }

@media only screen and (max-width: 420px) {
        .testspan {
            font-size: .9rem;
            top: calc(80% - 4vh);
        }
 }

@media only screen and (max-height: 559px) and (orientation : landscape){
    .testspan {
        font-size: .9rem;
        top: calc(80% - 7vh);
    }
}

@media only screen and (max-width: 280px){
    .testspan {
        font-size: .4rem;
    }
    
}

@media only screen and (min-width : 1024px) and (max-width : 767px) {
    // .infotab{
    //     padding : 0 24px;
    // }   
    .testspan {
    top: calc(80% - 5vh);
    }
}