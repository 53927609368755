@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.cicCheckbox input{
    align-self: center;

}
.labelHeading {
    display: inline;
}

.brand__img{
    text-align: center;
    margin-bottom: $spacing-m;

    .logoImage{
        height: 32px;
    }

}
.cicFeedbackHeading {
    text-align: center;
}
.asterisk {
    display: inline;
    color: $base-validation-error
}

.thanksText {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
}
.checkBox{
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
}

.actionButton {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-m;
    margin-top: $spacing-m;
}

.textArea {
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;
}

.loader_section {
    left: 50%;
    position: absolute;
    top: 50%;
}

.disabled{
    pointer-events: none;
    opacity: 0.5;
}
.errorText {
    color: $base-validation-error;
}