@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.logoff__text p {
    text-align: center;
    margin-bottom: $spacing-m !important;
}

.logoffheader {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -0.32px;
    margin-bottom: $spacing-m;

}

.logoff_section {
    // margin-top: 10%;
}

.log_off_header  {

    font-size: 28px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -0.32px;
    margin-bottom: $spacing-m;
}

@media only screen and (min-width: 800px) {

    .logoff__text {
        margin: 0 30%;
    }
    
    
}