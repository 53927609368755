@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';


.apFeedbackHeading {
    text-align: center;
}

.thanksText {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
}

.divider {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    border-color: $base-decoration-secondary !important;
}

.actionButton {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-m;
    margin-top: $spacing-m;
}

.textArea {
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;
}

.loader_section {
    left: 50%;
    position: absolute;
    top: 50%;
}

.apiErrorBanner {
    position: sticky;
    top: 0%;
    z-index: 1;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.RadioFeedbackCard {
    padding-top: $spacing-s;
    padding-bottom: $spacing-s;

    .APFeedbackRadioGroup {
        display: flex;

        .APFeedbackRadioEach {
            margin-right: auto;
            flex-direction: column;

        }

        .labelText {
            margin-left: 7px;
        }
    }

    .labelHeading {
        display: inline;
    }

     .asterisk {
        display: inline;
        color: $base-validation-error
    }



    .agreeDisagreeBox {
        display: flex;
        justify-content: space-between;

        .text {
            font-size: 15px;
            line-height: 24px;
            color: $base-text-meta !important
        }

        .agree {
            text-align: right;
        }
    }

    .errorText {
        color: $base-validation-error;
    }
}