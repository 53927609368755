@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.personal_data {
    text-align : center;
    // font-weight : bold;
    font-size : 24px;
    line-height : 28px;
    // font-family : 'HM Sans Semi Bold'
}

.bpDataSection .notification__section {
    margin-top: -25px;
    background: #93cdff;
    border-radius: 0px;
    margin-bottom: 30px;
    padding: 10px;
    position: relative;
    
}

.hm__data_box .loader_area {
    position: absolute;
    top: -28px;
    /* bottom: -67px; */
    left: 20px;
    /* right: -34px; */
}
.error_section {
    display: flex;
    align-items: center; 
    gap: 16px; 
    margin-top: 24px;
  }
  .error_section p{
    color: #D6001C;
}
  



.bpDataSection .notification__section p, .bpDataSection .notification__section a {
    /* align-items: baseline; */
    text-align: left;
    color: #222222;
    // font-family: "HM Sans Bold", sans-serif;
    // font-size: 12px;
    // font-weight: bold;
    line-height: 16px;
}

.green-color {
    fill: #4CAF50;
    margin-right: 10px;
}

.delete__services p{
    // margin-left: 8px;
    color: #4CAF50;
    // font-family: 'HM Sans Regular', sans-serif;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
}

.delete__service_err p{
    // margin-left: 8px;
    color: #D6001C;
    // font-family: 'HM Sans Regular', sans-serif;
    // font-size: 12px;
    font-weight: normal;
    height: 32px;
    letter-spacing: 0px;
    line-height: 16px;
}

.text-alignment {
    display: inline-block;
    width: 100%;
    padding: 25px 0px 10px 0px;
    justify-content: space-between;
    align-items: baseline;
}

.err_link {
    float: right;
    padding: 10px 0px 10px 0px;
}

.close__handler {
    cursor: pointer;
}

.delete__services {
    cursor: pointer;
    // margin-top: 20px;
    padding-bottom: 1%;
    display: flex;
}

.delete__services img {
    margin-right: 10px;
    height: 16px;
    width: 16px;
}

.content_holder {
    font-size: 17px;
    line-height: 28px;
    // font-family: 'HM Sans Regular', sans-serif;
    text-align: left;    
}



.flex__box {
    justify-content: space-between;
    align-items: center;
    display: flex;
    // margin-bottom: 15px;
}

// .error-msg-padding {
//     margin-bottom: 15px;
// }

.hm__h2 {
    margin: 0;
    font-size: 17px;
    line-height: 28px;
    // font-family: 'HM Sans Semi Bold', sans-serif;
}

.hm__data_box  {
    text-align: left;
    background-color: #ffff;
    min-height: 120px;
    padding: 16px 20px;
    position: relative;
}
.hm__data_box h4{
margin:0
}

.hm__ul_list {
    margin-top: 25px;
    margin-bottom: 45px;
}
.hm__ul_list h4, .hmm__ul_list h4, .hm__ul_list_with_indent h4{ 
    // font-family: 'HM Sans Regular', sans-serif;
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 8px;
}

._zeromargin h4 {
    margin-bottom: 0px;
} 

._extramargin {
    margin-bottom: 20px;
}

._extramargin30 {
    margin-bottom: 30px; 
}

._extramargintop30 {
    margin-top: 32px;
}

.hm__ul_list_with_indent {
    margin-top: 20px;
    margin-bottom: 0;
}

.hm__ul_list_with_indent h4 {
    margin-bottom: 0;
}

.hm__data_box a {
    text-decoration: underline;
    // font-family: 'HM Sans Regular', sans-serif;
    line-height: 28px;
    font-size: 17px;
    // text-align: right;
}
.privacy__anchor {
    margin-bottom: 6px;
    bottom: 1px;
}

.marginBotton {
    margin-bottom: 20px;
}

.brands__puff_holder .loader_section {
    
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
}
.div_brand_puff_logo {
    text-align: left;
    padding-left: 2px;
    margin-bottom: 12px;
}

.brands__puff_holder {
    position: relative;
    margin-bottom: $spacing-xxxxxl;
    margin-top: 30px;
}

.brand__box .hm__data_box {
    margin-bottom: 12px;
}

.brand__box {
    margin-bottom: 32px;
}

.bpDataSection{
    background-color : #FAFAFA;
}

@media only screen and (max-width : 767px) {
    .bpDataSection{
        padding : 0 24px;
    }   
}

@media only screen and (max-width: 430px) {
.delete__services {
    cursor: pointer;
    // margin-top: 20px;
    padding-bottom: 5%;
    display: flex;
}
}

.infoIconMargin {
    align-self: center;
    margin-right: 12px;
}