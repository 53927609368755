@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.personal__data {
    margin-top: $spacing-m !important;
    z-index: 555;
    margin-bottom: $spacing-xxxxxl;
}

.brandLogo__title_section .brand__img {
    text-align: center;
}


.edit__text {
    margin-top: 20px;
    margin-bottom: 33.5px;
    padding: 0 35px;
    text-align: center;
}

.data__text_holder {
    padding: 0 24px;
}

.data__text_holder p {
    color: rgb(34, 34, 34);
    font-size: 17px;
    font-weight: normal;
    line-height: 28px;
}

.data__text_holder a {
    text-decoration: underline;
    font-size: 17px;
    line-height: 28px;
}

.cta__buttons {
    position: relative;
    padding: 0 24px;
}

.cta__buttons .loader_area {
    position: absolute;
    bottom: 70px;
    right: 46%;
}

.submit__btn:disabled {
    // background-color: #707070;
    // color: #fff;
    // border-color: #707070;
    cursor: not-allowed,
}

@media only screen and (min-width: 768px) {
    .personal__data {
        width: 633px;
        margin: 0 auto;
        margin-bottom: $spacing-xxxxxl;
    }
}
