@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';
@media only screen and (min-width: 768px) {
    .parent-container {
     width: 633px;
     margin: auto;
     margin-top: $spacing-m;
    }

    .header-logo {
      width: 633px;
      margin: auto;
    }
   }

   .parent-container{
    margin-top: $spacing-m;
   }


   @media only screen and (max-width: 430px) {
    .parent-container {
        padding-right: $spacing-s;
        padding-left: $spacing-s;
        padding-bottom: $spacing-xxxxxl;
    }
    }

    @media only screen and (min-width: 431px) and (max-width: 767px) {
        .parent-container {
            padding-right: $spacing-s;
            padding-left: $spacing-s;
            padding-bottom: $spacing-xxxxxl;
        }
    }


.spacing{
    margin-bottom: $spacing-m;
}

.headingText{
    text-align: center;
}


    .actionButton {
        display: flex;
        flex-direction: column;
        row-gap: $spacing-m;
        margin-top: $spacing-m;
    }

