
@font-face {
     font-family: 'HM Group Ampersand Regular';
     src: local('HM Group Ampersand Regular'),
          url("./fonts/HMGroupAmpersand-Regular.woff2") format('woff2');
     }
   
     @font-face {
        font-family: 'HM Group Ampersand Bold';
        src: local('HM Group Ampersand Bold'),
             url("./fonts/HMGroupAmpersand-Bold.woff2") format('woff2');
        }
