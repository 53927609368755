@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

.spacing{
    margin-bottom: $spacing-m;
}

.brand__img{
    text-align: center;
    margin-bottom: $spacing-m;

    .logoImage{
        height: 32px;
    }

}

.cicVerificationHeading {
    text-align: center;
}

.pop_up_width{
    width: 343px !important;
}

.actionButton {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-m;
    margin-top: $spacing-m;
}

@media (min-width: 420px) {
    .apiSucessErrorToast > div {
      margin-left: 8px;
      width: 343px !important;
    }
  }

  @media (min-width: 420px) {
    .toast-error > div {
        margin-bottom: -5%;
    }
  }


    .toast-success > div {
        margin-bottom: 3%;
    }