@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

a,
.link {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
    font-size: inherit;
    text-transform: none;
}

.notification__section .active {
    display: flex;
}


.notification__section .not_active {
    display: none;
}

.access_section {
    margin-top: 10px;
    text-align: left;
    background-color: #ffff;
    min-height: 48px;
    // padding: 0px 10px 0px 10px;
}

.delete__account_section {
    margin-top: 10px;
    text-align: left;
    background-color: #ffff;
    min-height: 48px;
    // padding: 0px 10px 0px 10px;
}

.mpd-dashboard-link {
    display: flex;
    justify-content: center;
    margin: 24px 0px 24px 0px;
}


.download-label {
    margin: 24px 0px 0px;
    color: #515151;
}

.download__btn {
    width: 100%;
}

.manage__services_section {
    padding: 0 24px;
    padding-bottom: $spacing-xxxxxl;
    //margin-top: $spacing-m !important;
    margin-bottom: 15px !important;
}

.manage__services_section .brand__img {
    text-align: center;
}

.services__box {
    margin-bottom: 15px;
    // padding: 0 24px
}

.brand_puff_logo_manage_page{
    height: 40px;
    width: 40%;
}

.manage__services_section .edit__text {
    margin-top: 20px;
    margin-bottom: 32px;
    padding: 0 35px;
    text-align: center;
}

.services__box .helper__text p {
    // font-family: 'HM Sans Regular', sans-serif;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
}

.servicesheader {
    font-size: 17px;
    line-height: 28px;
}

.services__box .helper__text {
    margin-bottom: 32px;
}

.services_list {
    margin-top: 33px;
    margin-bottom: 24px;
}


.services_list h4,
.newservices_list h4 {
    // font-family: 'HM Sans Regular', sans-serif;
    line-height: 16px;
    font-size: 10px;
    margin-bottom: 4px;
    color: #707070
}

.edit__subscriptions_box .services_list div:last-child {
    margin-top: 14px;
}

.edit__subscriptions_box .newservices_list div:last-child {
    margin-top: 14px;
}

.services_list p,
.newservices_list p {
    // font-family: 'HM Sans Regular', sans-serif;
    line-height: 24px;
    font-size: 15px;
}

.MuiAccordionDetails-root {
    justify-content: space-between;
    align-items: flex-start;
}

.flexdiv {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    padding-top: 16px;
}

.align-manage-items span {
    display: flex;
}

.align-manage-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.align-with-link {
    font-family: 'HM Group Ampersand Regular';
    font-size: 17px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.32px;
    margin-top: $spacing-xs;
}

.managed_service_text {
    color: #515151;
    font-size: 15px;
    font-style: normal;
    font-weight: 400; 
    line-height: 24px; 
    letter-spacing: 0.4px;
}

.align-link {
    margin-bottom: 6px;
}

.line-height {
    // line-height: 1.65 !important;
}

.seprator_section {
    // margin-top: 10px;
}

.seprator {
    // border: 1px solid rgb(151, 151, 151);
    // height: 1px;
    // margin: 3% 16px;
    // // width: 90%;
    // display: block;
    // /* padding-left: 16px; */
    // background-color: rgb(151, 151, 151);
}

.edit__subscriptions_box {
    text-align: left;
    background-color: #ffff;
    min-height: 48px;
    // padding: 0px 10px 0px 10px;
}

.choice_box {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.anchoricon a {
    // margin-top: 8px;
    // min-height: 0px !important;
}

// .choice_box a , .anchoricon a{
//     text-decoration: underline;
//     font-family: 'HM Sans Regular', sans-serif;
//     line-height: 16px;
//     font-size: 12px;
//     text-align: right;
// }



.header__text {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    // text-transform: uppercase;
    // font-family: "HM Sans Semi Bold", sans-serif;
}

.font12 {
    font-size: 12px;
}


.overview__section img {
    border-radius: 0px;
    height: 24px;
    width: 24px;
}

.overview_control_section {
    display: flex;
    cursor: pointer;
}

.overview__section{
      position: sticky;
      top: 0;
      background-color: #FAFAFA;
      z-index: 10; 
      padding: $spacing-m 0;
      
}

// .seprator_section {
//     display: none;
// }

.services_request {
    cursor: pointer;
    display: flex;
    padding-bottom: 1%;
}

.services_request_closed {
    display: flex;
    padding-bottom: 1%;
}

.services_request img {
    margin-left: 10px;
    height: 16px;
    width: 16px;
}

.notification__section {
    // background: rgb(147, 205, 255);
    // border-radius: 0px;
    // margin-bottom: 30px;
    // padding: 10px;
    // position: relative;
    // width: 375px;
}

.close__handler {
    position: absolute;
    right: 10px;
    top: 10px;
}

// .notification__section p,.notification__section a {
//     color: rgb(34, 34, 34);
// // font-family: "HM Sans Bold", sans-serif;
// font-size: 12px;
// font-weight: bold;
// line-height: 16px;
// }

.services_deletion_text {
    color: #4caf50;
    // font-family: 'HM Sans Regular', sans-serif;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
}

.services_request_text {
    color: #4caf50;
    // font-family: 'HM Sans Regular', sans-serif;
    // font-size: 12px;
    // font-weight: normal;
    letter-spacing: 0px;
    font-size: 15px;
    line-height: 24px;
    // width: 220px;
}

.overview__section_text {
    // text-align: center;
    // margin: auto 0;
    margin: auto 10px;
    color: rgb(34, 34, 34);
    // font-family: 'HM Sans Regular', sans-serif;
    // font-size: 12px;
    font-weight: normal;
    height: 16px;
    line-height: 16px;
    width: 106px;
}

.apFeedback {
    text-align: center;
}

.displayInline {
    display: inline;
    color: #2C2C2C;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

@media only screen and (min-width: 520px) {
    .services__box {
        width: 413px;
        margin: auto;
    }
}

@media only screen and (min-width: 768px) {
    .manage__services_section {
        width: 633px;
        margin: 0 auto;
    }

    .services__box {
        width: 590px;
        margin: auto;
    }

    .services_msg {
        padding-top: 16px;
    }

    .access__services_text p {
        // width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .access__services_text p {
        // width: 65%;
    }

    .services_msg {
        padding-top: 16px;
    }
}

.infoIconMargin {
    align-self: center;
    margin-right: 12px;
}

.spacing-section {
    margin-bottom: 12px;
}