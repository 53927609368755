.demo1 {
    width : 100%;
    text-align : center;
  }

  

  .header-logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header-login-align {
    padding-left: 12%;
  }

  .centrealign {
    padding-left: 0%;
    display: block;
  }

  @media only screen and (min-width: 768px) {
    .demo1 {
     width: 633px;
     margin: auto;
    }

    .header-logo {
      width: 633px;
      margin: auto;
    }
   }

   


