@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';
.edit__subsciptions {

    .brand__img {
        text-align: center;
    }

    .edit__text {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .edit__div {
        text-align: center;
        margin: 0 auto;
    }

    .subscription-radio-grp {
        input {
            margin: 0 0 0 24px;
        }
    }

    .subscription-disclaimer {
        color: #515151;
    }
}

.RadioBtn_section .emailConsentText {
    color: #6D7278;
    padding: 0 0 0 43px;
    line-height: 16px;
    font-size: 12px;
}

.update_ctabuttons {
    position: relative;

    > .loader_area {
        position: absolute;
        bottom: 70px;
        right: 46%;
    }
}

.submit__btn {
    width: 100%;
    margin: $spacing-s 0px 0px;
}

.cancel__btn {
    width: 100%;
    margin: $spacing-s 0px 0px;
}

.mailmarketgrp {
    margin-top: 24px;
    margin-bottom: 24px;
}

.radio-group {
    margin: 0 0 0 24px;

    .radio-input {
        margin: 16px 0 16px 0;
    }
}

.info-text-subscription {
    font-size: 15px;
    margin: 10px 0 0 32px;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    visibility: hidden;
    height: 0;
    width: 0;

    &.visible {
        opacity: 1;
        visibility: visible;
        height: auto;
        width: auto;
        margin: 10px 0 16px 32px;
    }
}

@media only screen and (min-width: 768px) {
    .edit__subsciptions {
        width: 585px;
        margin-top: $spacing-m !important;
        margin: auto;
        margin-bottom: $spacing-xxxxxl;
    }
}

@media only screen and (max-width: 420px) {
    .edit__subsciptions {
        width: 90%;
        margin-top: $spacing-m !important;
        margin: auto;
        margin-bottom: $spacing-xxxxxl;
    }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
    .edit__subsciptions {
        width: 90%;
        margin: auto;
        margin-top: $spacing-m !important;
        margin-bottom: $spacing-xxxxxl;
    }
}

@media only screen and (max-width: 767px) {
    .brandLogo__title_section {
        padding: 0 24px;
    }
}

.spacing-m{
    margin-bottom: $spacing-m;
}

.spacing-s{
    margin-bottom: $spacing-s;
}
