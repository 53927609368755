@import '@hm-group/fabric-tokens/dist/exports/hmGroup.scss';

@media only screen and (min-width: 520px) {
  .login__form_box {
    width: 422px;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) {
  .login__form_box {
    width: 422px;
    margin: auto;
  }
}

.login__form_box {
  padding: 0 22px;
  padding-bottom: $spacing-xxxxxl !important;
}

.login__form_box {
  // width: 422px;
  margin: auto;
}

.spacing {
  margin-bottom: $spacing-m;
}

.brand-images {
  margin: 24px 0 24px;
  display: flex;
  justify-content: space-between;
}

.loader_section {
  left: 50%;
  position: absolute;
  top: 50%;
}

.input__wrapper {
  margin: 24px 0px;
  max-width: 100%;
  box-sizing: border-box;
  clear: both;
  position: relative;
}

.loginButton {
  display: flex;
  flex-direction: column;
}
