.not_found_header p {
    color: #222222;
    text-align: center;
    font-size: 36px;
    margin: 0 60px;
    // font-family: "HM Sans Semi Bold", sans-serif;
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 47.988px;
}

.unavailableLabel {
    padding: 0 30%;
}


.redirect__text p {
    color: #222222;
    font-size: 17px;
    line-height: 28px;
    margin: 0 auto;
    // font-family: 'HM Sans Regular', sans-serif;
    text-align: center;
}


@media screen and (min-width: 768px) {
    .not_found_header p {
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 39.99px;
    }

    .unavailableLabel {
        padding: 0 40%;
    }
}